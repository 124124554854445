import React from "react"
import HeaderLogo from "../assets/svgs/header-logo.svg"
import HeaderNavbar from "./headerNavbar"
import HeaderContacts from "./headerContacts"

export default function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="columns">
          <a href="#topo" className="column is-one-quarter">
            <picture className="header-logo">
              <HeaderLogo />
            </picture>
          </a>

          <div className="column is-hidden-mobile">
            <HeaderNavbar />
          </div>

          <div className="column is-narrow is-hidden-mobile">
            <HeaderContacts />
          </div>
        </div>
      </div>
    </header>
  )
}
