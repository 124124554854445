import React from "react"

export default function HeaderNavbar() {
  const navItems = [
    {
      label: "Serviços",
      link: "#servicos",
    },
    {
      label: "Clientes Atendidos",
      link: "#clientes-atendidos",
    },
    {
      label: "Sobre Nós",
      link: "#sobre-nos",
    },
    {
      label: "Contato",
      link: "#contato",
    },
  ]

  function setupItems() {
    return navItems.map(item => (
      <a key={item.link} href={item.link} className="navbar-item">
        {item.label}
      </a>
    ))
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand is-hidden-desktop"></div>
      <div className="navbar-menu">
        <div className="navbar-start">{setupItems()}</div>
      </div>
    </nav>
  )
}
