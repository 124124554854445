import React from "react"
import { FaPhone, FaWhatsapp, FaEnvelope } from "react-icons/fa"

import PropTypes from "prop-types"

export default function HeaderContacts({ footer }) {
  return (
    <ul className={`header-contacts ${footer && "footer"}`}>
      <li className="item">
        <FaPhone />
        <a
          href="tel:+551125248172"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          11 <strong>2524-8172</strong>
        </a>
      </li>

      <li className="item">
        <div className="round-icon">
          <FaWhatsapp />
        </div>
        <a
          href="https://wa.me/5511998422797"
          target="_blank"
          rel="noreferrer noopener"
          className="link"
        >
          11 <strong>99842-2797</strong>
        </a>
      </li>
    </ul>
  )
}

HeaderContacts.propTypes = {
  footer: PropTypes.bool,
}

HeaderContacts.defaultProps = {
  footer: false,
}
